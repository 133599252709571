<template>
  <div>
    <corpApply :corpId="corpId"></corpApply>
  </div>
</template>

<script>
import corpApply from '@/views/frm/corp/corpApprove/index.vue'
export default {
  components:{
    corpApply
  },
  data() {
    return {
      corpId: null
    }
  },
  created() {
    this.corpId = this.getCorpId()
  }
}
</script>

<style>
</style>